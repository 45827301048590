<template>
  <li class="history-table__item">
    <div class="history-table__col history-table__col--first">
      <div class="history-table__text">
        <content-loader
          viewBox="0 0 80 16"
          height="16"
          :speed="2"
          primary-color="#f3f3f3"
          secondary-color="#ecebeb"
        >
          <rect
            x="0"
            y="0"
            rx="0"
            ry="0"
            width="80"
            height="16"
          />
        </content-loader>
      </div>
    </div>
    <div class="history-table__col history-table__col--second">
      <div class="history-table__text">
        <content-loader
          viewBox="0 0 100 16"
          height="16"
          :speed="2"
          primary-color="#f3f3f3"
          secondary-color="#ecebeb"
        >
          <rect
            x="0"
            y="0"
            rx="0"
            ry="0"
            width="100"
            height="16"
          />
        </content-loader>
      </div>
    </div>
    <div class="history-table__col history-table__col--third">
      <div class="history-table__text">
        <content-loader
          class="order-company-placeholder"
          viewBox="0 0 150 20"
          height="20"
          :speed="2"
          primary-color="#f3f3f3"
          secondary-color="#ecebeb"
        >
          <rect
            x="0"
            y="0"
            rx="0"
            ry="0"
            width="150"
            height="16"
          />
        </content-loader>
      </div>
    </div>
    <div class="history-table__col history-table__col--fourth">
      <div class="history-table__text">
        <content-loader
          viewBox="0 0 80 16"
          height="16"
          :speed="2"
          primary-color="#f3f3f3"
          secondary-color="#ecebeb"
        >
          <rect
            x="0"
            y="0"
            rx="0"
            ry="0"
            width="80"
            height="16"
          />
        </content-loader>
      </div>
    </div>
    <div class="history-table__col history-table__col--combined">
      <div class="history-table__row">
        <div class="history-table__text">
          <content-loader
            viewBox="0 0 80 18"
            height="18"
            :speed="2"
            primary-color="#f3f3f3"
            secondary-color="#ecebeb"
          >
            <rect
              x="0"
              y="0"
              rx="0"
              ry="0"
              width="80"
              height="16"
            />
          </content-loader>
        </div>
        <div class="history-table__status">
          <content-loader
            viewBox="0 0 80 18"
            height="18"
            :speed="2"
            primary-color="#f3f3f3"
            secondary-color="#ecebeb"
          >
            <rect
              x="0"
              y="0"
              rx="0"
              ry="0"
              width="80"
              height="16"
            />
          </content-loader>
        </div>
        <div class="history-table__status">
          <content-loader
            viewBox="0 0 80 18"
            height="18"
            :speed="2"
            primary-color="#f3f3f3"
            secondary-color="#ecebeb"
          >
            <rect
              x="0"
              y="0"
              rx="0"
              ry="0"
              width="80"
              height="16"
            />
          </content-loader>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'

export default {
  components: {
    ContentLoader
  }
}
</script>

<style lang="scss" scoped>
.history-table__col--third rect {
  @media screen and (max-width: 1199px) {
    y: 6;
  }
}
</style>
