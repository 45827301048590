export const PROCESSING = 0
export const ASSEMBLING = 1
export const DELIVERING = 2
export const DELIVERED = 3
export const PARTIALLY_COMPLETED = 4
export const CANCELLED = 5
export const ERROR = 6

const icons = {
  [PROCESSING]: 'waiting',
  [ASSEMBLING]: 'waiting',
  [DELIVERING]: 'waiting',
  [DELIVERED]: 'done',
  [PARTIALLY_COMPLETED]: 'done',
  [CANCELLED]: 'warning',
  [ERROR]: 'warning'
}

const titles = {
  [PROCESSING]: 'В обработке',
  [ASSEMBLING]: 'На сборке',
  [DELIVERING]: 'Ожидает доставки',
  [DELIVERED]: 'Доставлен',
  [PARTIALLY_COMPLETED]: 'Частично завершен',
  [CANCELLED]: 'Отменен',
  [ERROR]: 'Корректировка заявки'
}

export const getStatusTitle = (status) => {
  return titles[status]
}

export const getStatusIcon = (status) => {
  return icons[status]
}