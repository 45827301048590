export const PAID = 0
export const NOT_PAID = 1
export const PREPAYMENT_REQUIRED = 2
export const CANCELED = 3
export const STOPPED = 4

const classes = {
  [PAID]: 'caption--gray',
  [NOT_PAID]: 'caption--gray',
  [PREPAYMENT_REQUIRED]: 'caption--waiting',
  [CANCELED]: 'caption--warning',
  [STOPPED]: 'caption--warning',
  null: 'caption--gray'
}

const icons = {
  [PAID]: 'done',
  [NOT_PAID]: 'waiting',
  [PREPAYMENT_REQUIRED]: 'waiting',
  [CANCELED]: 'warning',
  [STOPPED]: 'warning'
}

const titles = {
  [PAID]: 'Оплачен',
  [NOT_PAID]: 'Не оплачен',
  [PREPAYMENT_REQUIRED]: 'Требует предоплаты',
  [CANCELED]: 'Отменен',
  [STOPPED]: 'Стоп-отгрузка'
}

export const getStatusTitle = (status) => {
  return titles[status]
}

export const getStatusIcon = (status) => {
  return icons[status]
}

export const getStatusCaptionClass = (status) => {
  return classes[status]
}
