<template>
  <li class="history-table__item">
    <router-link :to="`/orders/${order.id}`" class="history-table__link">
      <span class="visually-hidden">Перейти на страницу заказа</span>
    </router-link>
    <div class="history-table__col history-table__col--first">
      <div class="history-table__text">
        {{ order.number ? order.number : '-' }}
      </div>
    </div>
    <div class="history-table__col history-table__col--second">
      <div class="history-table__text">
        {{ date }}
      </div>
    </div>
    <div class="history-table__col history-table__col--third">
      <div class="history-table__text">
        {{ order.company_name }}
      </div>
    </div>
    <div class="history-table__col history-table__col--fourth">
      <div class="history-table__text address-block" v-html="formatAddress(order.shipping_address.address)">
      </div>
      <div class="address-popup">
        {{ order.shipping_address.address }}
      </div>
    </div>
    <div class="history-table__col history-table__col--fifth">
      <div class="history-table__text">
        {{ total }}
      </div>
    </div>
    <div class="history-table__col history-table__col--combined">
      <order-list-item-suborder
        v-for="suborder in order.suborders"
        :key="suborder.id"
        :suborder="suborder"
        class="history-table__row"
      />
    </div>
  </li>
</template>

<script>
import dayjs from 'dayjs'
import OrderListItemSuborder from './OrderListItemSuborder.vue'
import { computed } from 'vue'
import { formatCurrency } from '@/common/formatNumbers'
import { formatAddress } from '@/common/formatAddress'

export default {
  components: {
    OrderListItemSuborder
  },
  props: {
    order: {
      type: Object,
      required: true,
    }
  },
  setup (props) {
    const total = computed(() => formatCurrency(props.order.total))
    const date = computed(() => dayjs.unix(props.order.created_at).format('DD.MM.YYYY HH:mm'))
    return {
      date,
      total,
      formatAddress
    }
  }
}
</script>
