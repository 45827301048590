<template>
  <section>
    <div class="page-header page-header--filter-mobile">
      <div class="container">
        <div class="page-header__inner">
          <div class="page-header__left">
            <h1>История заказов</h1>
            <div class="page-header__quantity">
              {{ pagination.totalCount }}
            </div>
          </div>
          <div class="search-block search-block--orders page-header__right">
            <form class="search-block__form" @submit.prevent>
                <div class="search-block__label">
                <vue-multiselect
                  id="customer"
                  v-model="selectedCustomer"
                  :options="customers"
                  :multiple="false"
                  :searchable="true"
                  placeholder="Пользователь"
                  track-by="id"
                  :show-labels="false"
                  :internal-search="false"
                  :loading="isCustomersLoading"
                  @search-change="fetchCustomersDebounced"
                  @update:model-value="updateRouter"
                >
                  <template #noOptions>
                    Список пользователей пуст
                  </template>
                  <template #singleLabel="{ option }">
                    <div class="select__subtitle">
                      {{ option.name }}
                    </div>
                  </template>
                  <template #option="{ option }">
                    <div class="select__subtitle">
                      {{ option.name }}
                    </div>
                  </template>
                  <template #clear>
                    <span v-if="selectedCustomer" class="multiselect__clear" @mousedown.prevent.stop="clearCustomer">
                      <svg class="s-icon"><use xlink:href="/img/svg/sprite1.svg#cancel" /></svg>
                    </span>
                  </template>
                  <template #noResult>
                    Ничего не найдено.
                  </template>
                </vue-multiselect>
              </div>
              <div class="search-block__label address-search">
                <vue-multiselect
                  id="address"
                  v-model="selectedAddress"
                  :options="addresses"
                  :multiple="false"
                  :searchable="true"
                  placeholder="Адрес доставки"
                  track-by="id"
                  :show-labels="false"
                  :internal-search="false"
                  :loading="isAddressesLoading"
                  @search-change="fetchAddressesDebounced"
                  @update:model-value="updateRouter"
                >
                  <template #noOptions>
                    Список адресов пуст
                  </template>
                  <template #singleLabel="{ option }">
                    <div class="select__subtitle">
                      {{ option.address ? option.address : option.name }}
                    </div>
                  </template>
                  <template #option="{ option }">
                    <div class="select__subtitle">
                      {{ option.address ? option.address : option.name }}
                    </div>
                  </template>
                  <template #clear>
                    <span v-if="selectedAddress" class="multiselect__clear" @mousedown.prevent.stop="clearAddress">
                      <svg class="s-icon"><use xlink:href="/img/svg/sprite1.svg#cancel" /></svg>
                    </span>
                  </template>
                  <template #noResult>
                    Ничего не найдено.
                  </template>
                </vue-multiselect>
              </div>
              <label class="search-block__label">
                <span class="visually-hidden">№ заказа/договора или сумме заказа</span>
                <input
                  v-model="searchQuery"
                  class="search-block__input"
                  type="search"
                  placeholder="Сумма и № заказа/договора"
                  @input="debouncedUpdateRouter"
                >
                <button v-if="searchQuery" class="search-block__icon" @click="clearSearchQuery">
                  <span class="visually-hidden">Искать</span>
                  <svg class="s-icon">
                    <use xlink:href="/img/svg/sprite1.svg#cancel" />
                  </svg>
                </button>
              </label>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="preloader-container" :class="{ 'preloader-container--loading': !showPlaceholders && isLoading }">
      <transition name="fade">
        <preloader v-if="!showPlaceholders && isLoading" />
      </transition>
      <div ref="ordersContainer" class="history-table">
        <div class="container">
          <order-list-placeholder v-if="showPlaceholders" />
          <order-list v-else :orders="orders" />
        </div>
      </div>
      <paginate
        v-if="pagination.pageCount > 1"
        v-model="pagination.currentPage"
        :page-count="pagination.pageCount"
        :scrollable-element="ordersContainer"
        @paginate="paginate"
        @scroll-paginate="onScrollPaginate"
      />
    </div>
  </section>
</template>

<script>
import VueMultiselect from 'vue-multiselect'
import Paginate from '@/components/Paginate'
import OrderListPlaceholder from '../components/placeholders/OrderListPlaceholder.vue'
import OrderList from '@/components/orders/OrderList'
import parsePaginationHeaders from '@/common/parsePaginationHeaders'
import Preloader from '@/components/Preloader.vue'
import debounce from 'lodash/debounce'
import { ref, computed } from 'vue'
import { useApi } from '@/api'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'
import { FETCH_ORDERS } from '@/store/modules/orders/actionTypes'
import { useStore } from 'vuex'

const addresses = ref([])
const customers = ref([])
const showPlaceholders = ref(true)
const selectedAddress = ref(null)
const selectedCustomer = ref(null)
const searchQuery = ref(null)
const pagination = ref({})
const searchParams = computed(() => {
  return {
    customer_id: selectedCustomer.value?.id,
    shipping_address_id: selectedAddress.value?.id,
    query: searchQuery.value,
    page: pagination.value.currentPage,
    expand: 'suborders,shipping_address'
  }
})
const fetchOrders = (store, toast, append) => {
  store.dispatch(FETCH_ORDERS, { append, params: searchParams.value }).then(({ headers }) => {
    pagination.value = parsePaginationHeaders(headers)
  }).catch(() => {
    toast.error('Не удалось загрузить список заказов')
  }).finally(() => {
    showPlaceholders.value = false
  })
}
const parseFiltersFromRoute = (route, api) => {
  return new Promise((resolve) => {
    const { customer_id, shipping_address_id, query } = route.query
    let { page } = route.query
    page = parseInt(page, 10) || 1
    pagination.value.currentPage = page
    searchQuery.value = query
    const address = addresses.value.find(item => item.id === shipping_address_id)
    const customer = customers.value.find(item => item.id === customer_id)
    if (address) {
      selectedAddress.value = address     
    } else if (shipping_address_id) {
      api.addresses.fetch(shipping_address_id).then(({ data }) => {
        selectedAddress.value = data
      }).catch(() => {
        selectedAddress.value = null
      })
    } else {
      selectedAddress.value = null      
    }
    if (customer) {
      selectedCustomer.value = customer     
    } else if (customer_id) {
      api.customers.fetch(customer_id).then(({ data }) => {
        selectedCustomer.value = data
      }).catch(() => {
        selectedCustomer.value = null
      })
    } else {
      selectedCustomer.value = null      
    }
    resolve()
  })
}

export default {
  components: {
    VueMultiselect,
    OrderList,
    Paginate,
    OrderListPlaceholder,
    Preloader
  },
  beforeRouteEnter() {
    showPlaceholders.value = true
  },
  beforeRouteUpdate(to) {
    parseFiltersFromRoute(to, this.$api).then(() => fetchOrders(this.$store, this.$toast))
  },
  setup () {
    const api = useApi()
    const toast = useToast()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const ordersContainer = ref(null)
    const isAddressesLoading = ref(false)
    const isCustomersLoading = ref(false)

    const isLoading = computed(() => store.getters.isOrdersLoading)
    const orders = computed(() =>  store.getters.getOrders)

    const updateRouter = () => {
      router.push({ path: '/orders', query: searchParams.value })
    }
    const debouncedUpdateRouter = debounce(updateRouter, process.env.VUE_APP_DEBOUNCE_TIME)
    const onScrollPaginate = () => {
      const { currentPage, pageCount } = pagination.value
      if (!showPlaceholders.value && !isLoading.value && currentPage < pageCount) {
        pagination.value.currentPage++
        fetchOrders(store, toast, true)
      }
    }
    const fetchAddresses = (query) => {
      isAddressesLoading.value = true
      api.addresses.search({ query, with_orders: 1 }).then(({ data }) => {
        addresses.value = data
      }).finally(() => {
        isAddressesLoading.value = false
      })
    }
    const fetchCustomers = (query) => {
      isCustomersLoading.value = true
      api.customers.search({ query }).then(({ data }) => {
        customers.value = data
      }).finally(() => {
        isCustomersLoading.value = false
      })
    }
    const fetchAddressesDebounced = debounce(fetchAddresses, process.env.VUE_APP_DEBOUNCE_TIME)
    const fetchCustomersDebounced = debounce(fetchCustomers, process.env.VUE_APP_DEBOUNCE_TIME)

    const paginate = () => {
      updateRouter()
    }

    parseFiltersFromRoute(route, api).then(() => {
      fetchOrders(store, toast)
      fetchAddresses()
      fetchCustomers()
    })
    const clearCustomer = () => {     
      selectedCustomer.value = null
      updateRouter()
    }
    const clearAddress = () => {
      selectedAddress.value = null     
      updateRouter()
    }
    const clearSearchQuery = () => {
      searchQuery.value = null
      updateRouter()
    }
    return {
      isLoading,
      clearSearchQuery,
      clearCustomer,
      clearAddress,
      paginate,
      selectedAddress,
      addresses,
      isAddressesLoading,
      fetchAddressesDebounced,
      selectedCustomer,
      customers,
      isCustomersLoading,
      fetchCustomersDebounced,
      showPlaceholders,
      onScrollPaginate,
      ordersContainer,
      updateRouter,
      debouncedUpdateRouter,
      searchQuery,
      pagination,
      orders
    }
  }
}
</script>
