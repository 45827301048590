<template>
  <div class="pagination">
    <div class="container">
      <div class="pagination__inner">
        <button
          :tabindex="firstPageSelected ? -1 : 0"
          class="pagination__btn pagination__btn--left"
          :disabled="firstPageSelected"
          @click="prevPage()"
          @keyup.enter="prevPage()"
        >
          <span class="visually-hidden">Предыдущая страница</span>
          <svg class="s-icon s-icon--largest">
            <use xlink:href="/img/svg/sprite1.svg#triangle-prev" />
          </svg>
        </button>
        <ul class="pagination__list">
          <li
            v-for="page in pages"
            :key="page.index"
            class="pagination__item"
            :class="{ 'pagination__item--active': page.selected }"
          >
            <span v-if="page.breakView" class="pagination__dots">...</span>
            <a v-else-if="page.disabled" class="pagination__link" tabindex="0">{{ page.content }}</a>
            <a
              v-else
              class="pagination__link"
              tabindex="0"
              @click="handlePageSelected(page.index + 1)"
              @keyup.enter="handlePageSelected(page.index + 1)"
            >{{ page.content }}</a>
          </li>
        </ul>
        <button
          :tabindex="lastPageSelected ? -1 : 0"
          :disabled="lastPageSelected"
          class="pagination__btn pagination__btn--right"
          @click="nextPage()"
          @keyup.enter="nextPage()"
        >
          <span class="visually-hidden">Следующая страница</span>
          <svg class="s-icon s-icon--largest">
            <use xlink:href="/img/svg/sprite1.svg#triangle-next" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import throttle from 'lodash/throttle'
import { ref, computed, onMounted, onUnmounted } from 'vue'

export default {
  props: {
    scrollableElement: {
      type: HTMLElement,
      default: null
    },
    scrollBreakpoint: {
      type: Number,
      default: 992
    },
    modelValue: {
      type: Number,
      default: null
    },
    pageCount: {
      type: Number,
      required: true
    },
    pageRange: {
      type: Number,
      default: 3
    },
    marginPages: {
      type: Number,
      default: 2
    }
  },
  emits: ['update:modelValue', 'paginate', 'scrollPaginate'],
  setup (props, { emit }) {
    const innerValue = ref(1)
    const selected = computed({
      get: function() {
        return props.modelValue || innerValue.value
      },
      set: function(newValue) {
        innerValue.value = newValue
      }
    })
    const firstPageSelected = computed(() => selected.value === 1)
    const lastPageSelected = computed(() => selected.value === props.pageCount || props.pageCount === 0)
    const handlePageSelected = (selected) => {
      if (selected.value === selected) return
      emit('update:modelValue', selected)
      innerValue.value = selected
      emit('paginate', selected)
    }
    const prevPage = () => {
      if (selected.value <= 1) return
      handlePageSelected(selected.value - 1)
    }
    const nextPage = (scroll = false) => {
      if (selected.value >= props.pageCount) return
      handlePageSelected(selected.value + 1, scroll)
    }
    const selectFirstPage = () => {
      if (selected.value <= 1) return
      handlePageSelected(1)
    }
    const selectLastPage = () => {
      if (selected.value >= props.pageCount) return
      handlePageSelected(props.pageCount)
    }
    const pages = computed(() => {
      let items = {}
      if (props.pageCount <= props.pageRange) {
        for (let index = 0; index < props.pageCount; index++) {
          let page = {
            index: index,
            content: index + 1,
            selected: index === (selected.value - 1)
          }
          items[index] = page
        }
      } else {
        const halfPageRange = Math.floor(props.pageRange / 2)
        let setPageItem = index => {
          let page = {
            index: index,
            content: index + 1,
            selected: index === (selected.value - 1)
          }

          items[index] = page
        }
        let setBreakView = index => {
          let breakView = {
            disabled: true,
            breakView: true
          }
          items[index] = breakView
        }
        // 1st - loop thru low end of margin pages
        for (let i = 0; i < props.marginPages; i++) {
          setPageItem(i)
        }
        // 2nd - loop thru selected range
        let selectedRangeLow = 0
        if (selected.value - halfPageRange > 0) {
          selectedRangeLow = selected.value - 1 - halfPageRange
        }
        let selectedRangeHigh = selectedRangeLow + props.pageRange - 1
        if (selectedRangeHigh >= props.pageCount) {
          selectedRangeHigh = props.pageCount - 1
          selectedRangeLow = selectedRangeHigh - props.pageRange + 1
        }
        for (let i = selectedRangeLow; i <= selectedRangeHigh && i <= props.pageCount - 1; i++) {
          setPageItem(i)
        }
        // Check if there is breakView in the left of selected range
        if (selectedRangeLow > props.marginPages) {
          setBreakView(selectedRangeLow - 1)
        }
        // Check if there is breakView in the right of selected range
        if (selectedRangeHigh + 1 < props.pageCount - props.marginPages) {
          setBreakView(selectedRangeHigh + 1)
        }
        // 3rd - loop thru high end of margin pages
        for (let i = props.pageCount - 1; i >= props.pageCount - props.marginPages; i--) {
          setPageItem(i)
        }
      }
      return items
    })

    const isElementAtBottom = (target, threshold = 50) => {
      const { bottom } = target.getBoundingClientRect()
      return bottom < window.innerHeight + threshold
    }
    const onScroll = throttle(() => {
      if (
        selected.value < props.pageCount && window.innerWidth < props.scrollBreakpoint
        && props.scrollableElement && isElementAtBottom(props.scrollableElement)
      ) {
        emit('scrollPaginate', selected.value)
      }
    }, 250)
    onMounted(() => {
      window.addEventListener("scroll", onScroll)
    })
    onUnmounted(() => {
      window.removeEventListener("scroll", onScroll)
    })
    return {
      innerValue,
      selected,
      firstPageSelected,
      lastPageSelected,
      pages,
      nextPage,
      prevPage,
      handlePageSelected,
      selectFirstPage,
      selectLastPage
    }
  }
}
</script>
