const MAX_LENGTH = 25
const SPLIT_SYMBOL = "<br />"

export const formatAddress = (address) => {
  if (address.length <= MAX_LENGTH) {
    return address;
  }
  let firstPart = address.slice(0, MAX_LENGTH)
  let secondPart = address.slice(MAX_LENGTH)
  if (firstPart.includes(',')) {
    firstPart = firstPart.replace(',', ',' + SPLIT_SYMBOL)
  }

  return firstPart + secondPart
}
