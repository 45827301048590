<template>
  <div class="history-table__inner">
    <ul class="history-table__header">
      <li class="history-table__title history-table__title--first">
        Номер
      </li>
      <li class="history-table__title history-table__title--second">
        Дата
      </li>
      <li class="history-table__title history-table__title--third">
        Плательщик
      </li>
      <li class="history-table__title history-table__title--fourth">
        Сумма заказа
      </li>
      <li class="history-table__title history-table__title--fifth">
        Договор
      </li>
      <li class="history-table__title history-table__title--sixth">
        Статус оплаты
      </li>
      <li class="history-table__title history-table__title--seventh">
        Статус заказа
      </li>
      <li class="history-table__title history-table__title--eighth" />
    </ul>
    <ul class="history-table__list">
      <order-list-item-placeholder v-for="index in placeholderCount" :key="index" />
    </ul>
  </div>
</template>

<script>
import OrderListItemPlaceholder from './OrderListItemPlaceholder.vue'

export default {
  components: {
    OrderListItemPlaceholder
  },
  props: {
    placeholderCount: {
      type: Number,
      default: 10
    }
  }
}
</script>
