export const NOT_REQUIRED = 0
export const REQUIRED = 1
export const DEFERRED = 2

const classes = {
  [REQUIRED]: 'caption--waiting',
  [NOT_REQUIRED]: 'caption--success',
  [DEFERRED]: 'caption--waiting',
  null: 'caption--warning'
}

const icons = {
  [REQUIRED]: 'waiting',
  [NOT_REQUIRED]: 'done',
  [DEFERRED]: 'waiting',
  null: 'warning'
}

const titles = {
  [REQUIRED]: 'Требует предоплаты',
  [NOT_REQUIRED]: 'Не требует предоплаты',
  [DEFERRED]: 'Отсроченный платеж',
  null: 'Недоступно для заказа'
}

export const getStatusCaptionClass = (status) => {
  return classes[status]
}

export const getStatusIcon = (status) => {
  return icons[status]
}

export const getStatusTitle = (status) => {
  return titles[status]
}