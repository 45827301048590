<template>
  <div class="history-table__inner">
    <ul class="history-table__header">
      <li class="history-table__title history-table__title--first">
        Номер
      </li>
      <li class="history-table__title history-table__title--second">
        Дата
      </li>
      <li class="history-table__title history-table__title--third">
        Плательщик
      </li>
      <li class="history-table__title history-table__title--fourth">
        Адрес доставки
      </li>
      <li class="history-table__title history-table__title--fifth">
        Сумма заказа
      </li>
      <li class="history-table__title history-table__title--sixth">
        Договор
      </li>
      <li class="history-table__title history-table__title--seventh">
        Статус заказа
      </li>
    </ul>
    <ul v-if="orders?.length" class="history-table__list">
      <order-list-item v-for="order in orders" :key="order.id" :order="order" />
    </ul>
    <p v-else class="not-found-message">
      Ничего не найдено
    </p>
  </div>
</template>

<script>
import OrderListItem from './OrderListItem.vue'

export default {
  components: {
    OrderListItem
  },
  props: {
    orders: {
      type: Array,
      required: true
    }
  }
}
</script>
